<template>
  <div class="Main">
    <b-container>
      <b-row>
        <b-col>
          <div class="Main__banner">
            <b-avatar
              class="mr-2"
              badge
              badge-variant="success"
              src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"
            />
            <b-form-input
              v-model="text"
              v-b-modal.postModal
              class="Main__input"
              placeholder="Quoi de neuf ? "
            />
          </div>
          <div class="Main__container">
            <div class="Main__separator" />
          </div>
          <b-modal
            id="postModal"
            size="lg"
            hide-header
            hide-footer
            class="Main__modal"
          >
            <template>
              <b-form-textarea
                v-model="post"
                size="lg"
                placeholder="Mon message"
                class="Main__modalInput"
              />
              <b-button
                variant="primary"
                class="mt-3 float-right"
              >Poster</b-button>
            </template>
          </b-modal>
        </b-col>
      </b-row>
    </b-container>

    <!-- CARD -->
    <b-container>
      <b-row>
        <div class="card-container mt-5">
          <b-card
            v-for="i in [0, 1, 2, 3]"
            :key="i"
            footer-bg-variant="light"
            class="Card"
          >
            <b-link><h4 class="Post__title">
              EARL de Baynac <span>a posté</span>
            </h4></b-link>
            <p class="mb-2 text-muted">
              <feather-icon
                icon="ClockIcon"
                size="12"
                class="mr-1"
              />Il y a 9
              jours
            </p>
            <p class="mb-2">
              Le Lorem Ipsum est simplement du faux texte employé dans la
              composition et la mise en page avant impression. Le Lorem Ipsum
              est le faux texte standard de l'imprimerie depuis les années 1500,
              quand un imprimeur anonyme assembla ensemble des morceaux de texte
              pour réaliser un livre spécimen de polices de texte. Il n'a pas
              fait que survivre cinq siècles, mais s'est aussi adapté à la
              bureautique informatique, sans que son contenu n'en soit modifié.
              Il a été popularisé dans les années 1960 grâce à la vente de
              feuilles Letraset contenant des passages du Lorem Ipsum, et, plus
              récemment, par son inclusion dans des applications de mise en page
              de texte, comme Aldus PageMaker.
            </p>
            <b-link
              variant="primary"
              @click="click"
            >
              Afficher la suite
            </b-link>
            <b-card
              class="mt-2 Post__img"
              img-src="https://images.unsplash.com/photo-1625246333195-78d9c38ad449?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
              alt="Image"
            />
            <div class="Post__avatarList d-inline">
              <feather-icon
                icon="ThumbsUpIcon"
                size="16"
              />
              <template>
                <b-avatar-group class="ml-1">
                  <b-avatar
                    size="25px"
                    src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"
                  />
                  <b-avatar
                    size="25px"
                    src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"
                  />
                  <b-avatar
                    size="25px"
                    src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"
                  />
                  <b-avatar
                    size="25px"
                    src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"
                  />
                </b-avatar-group>
              </template>
              <p class="d-inline text-muted ml-1 Post__avatarCount">
                +271 more
              </p>
            </div>
            <template #footer>
              <feather-icon
                icon="MessageSquareIcon"
                size="14"
              />
              <p class="d-inline ml-1">
                1.25k
              </p>
              <b-link
                class="ml-1 Post__commentLink"
              >Voir tous les commentaires</b-link>
              <b-list-group class="mt-1">
                <b-list-group-item class="d-flex">
                  <b-avatar
                    src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"
                    class="mr-2"
                  />
                  <h5 class="Post__userName">
                    J. Circlehead
                  </h5>
                </b-list-group-item>
                <p class="ml-5">
                  Le Lorem Ipsum est simplement du faux texte employé dans la
                  composition et la mise en page avant impression. Le Lorem
                  Ipsum est le faux texte standard de l'imprimerie depuis les
                  années 1500
                </p>
                <b-list-group-item class="d-flex align-items-center mt-1">
                  <b-avatar
                    variant="primary"
                    src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"
                    class="mr-1"
                    badge
                    badge-variant="success"
                  />
                  <b-form-input placeholder="Ajouter un commentaire..." />
                </b-list-group-item>
              </b-list-group>
            </template>
          </b-card>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BButton,
  BFormInput,
  BAvatar,
  BModal,
  BFormTextarea,
  BLink,
  BListGroup
} from "bootstrap-vue";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BButton,
    BFormInput,
    BAvatar,
    BModal,
    BFormTextarea,
    BLink,
    BListGroup
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.Main {
  position: relative;
  height: 100%;

  &__banner {
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
    background: #ebe9f1;
    border-radius: 6px;
  }

  &__input {
    width: 90%;
  }
}

.Post {
  &__title {
    color: #7367f0;
  }

  &__title span {
    color: grey;
  }

  &__img {
    margin: 0;
  }

  &__avatarList {
    margin-top: -20px;
    position: relative;
  }

  &__avatarCount {
    font-weight: 400;
    font-size: 0.875em;
  }

  &__thumb:hover {
    cursor: pointer;
  }

  &__commentLink {
    text-decoration: underline;
  }

  &__userName {
    margin-top: 5px;
  }
}

.card-container {
  position: relative;
  width: 100%;
  min-height: 2rem;
  margin-bottom: 5rem;
  display: inline-block;

  &::before {
    top: -20px;
    left: 50%;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 6px;
    background: #ebe9f1;
    border-radius: 6px;
    margin-left: -3px;
    z-index: -1;
  }
}

.Card {
  // Timeline styles
  float: left;
  max-width: 500px;

  &:nth-child(odd) {
    clear: left;
  }

  &:nth-child(even) {
    float: right;
    clear: right;
  }

  &:nth-child(2) {
    margin-top: 30px;
  }
  //
}

@media screen and (max-width: 640px) {
  .Main {
    &__input {
      width: 80%;
    }
  }

  .Card {
    width: 100%;
  }

  .card-container::before {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Card {
    width: 400px;
  }
}
</style>
